<template>
  <div class="container">
    <!--<div class="row">
      <vue-list-picker :left-items="leftItems" :right-items="rightItems" />
    </div>
    <div class="row">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="desserts"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              class="elevation-1"
              @page-count="pageCount = $event"
            ></v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
              <v-text-field
                :value="itemsPerPage"
                label="Items per page"
                type="number"
                min="-1"
                max="15"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  created() {
    this.fetchLocations();
  },
  data() {
    const example1 = {
      key: 1,
      content: "Item 1",
    };
    const example2 = {
      key: 2,
      content: "Item 2",
    };
    const example3 = {
      key: 3,
      content: "Item 3",
    };
    const example4 = {
      key: 4,
      content: "Item 4",
    };

    const leftItems = [example1, example2];
    const rightItems = [example3, example4];

    return {
      leftItems,
      rightItems,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Codígo", value: "code" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
    };
  },
  methods: {
    fetchLocations() {
      var vm = this;
      this.axios({
        url: "inventory/locations",
        method: "GET",
        data: {
          store_id: 1,
        },
      })
        .then((response) => {
          console.log(response.data.data);
          //this.loading = false;
          vm.desserts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>